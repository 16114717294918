.select-list {
    font-family: Arial, Helvetica, sans-serif;
    font-size: small;
    color: black;
    overflow-y: auto;
    max-height: 410px;
}

.select-item:hover {
    background: lightgrey;
}

.left-arrow-btn {
    margin-left: auto;
    margin-right: auto;
}

.right-arrow-btn {
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
}

.buttons {
    margin: auto;
}

.select-list-lbl {
    font-family: Arial, Helvetica, sans-serif;
    color: black;
    text-align: center;
    margin-bottom: 5px;
}

.edit-template-modal .modal-body {
    margin-top: -20px;
}

.new-template-modal .modal-body {
    margin-top: -20px;
}