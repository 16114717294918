.search-field .search-title {   
    font-size: 1rem;
    color: black;
    font-weight: bold;
}

.search-field .list-container {
    max-height: 240px;
    overflow-y: auto;
}

.search-cloud {
    font-size: small;
}

.cloud-span {
    display: inline-block;
		margin: 2px 5px;
		cursor: pointer;
		border-radius: 0.25rem;
		padding: 2px 4px;
}