.shopping-cart-modal tr:nth-last-child(even) {   
    background-color: #f2f2f2;
}

.table-foot {
    font-size: small;
    font-weight: bold;
    text-align: center;
}

.text-bold {
    font-weight: bold;
}

.th-size {
    min-width: 150px;
    text-align: center;
}

.td-pos {
    text-align: center;
    height: auto;
    vertical-align: bottom;
}

.list-value {
    margin-left: 15px;
}

.download-excel-body {
    margin: auto;
}