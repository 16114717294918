.subtable-dropdown {
    position: relative;
    display: inline-block;
  }
  
  .subtable-dropdown .subtable-dropdown-label {
      font-weight: 500;
      cursor: pointer;
  }
  
  .subtable-dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    /*min-width: 160px;*/
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    padding: 5px;
    /*padding: 0;*/
    z-index: 1;
    overflow: auto;
    max-height: 200px;
  }
  
  .subtable-dropdown-content span {
      color: black;
    padding: 5px 16px;
    text-decoration: none;
    display: block;
    cursor: pointer;
  }
  
  .subtable-dropdown-content span:hover {
      background-color: #007bff;
      color: white;
  }
  
  .subtable-dropdown-open .subtable-dropdown-content {
    display: block;
  }