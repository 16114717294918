.flip-card-label-wrap, .flip-card-label-wrap-flipped, .flip-card, .flip-card-flipped, .flip-card-mobile, .flip-card-mobile-flipped, .flip-card-no-buttons, .flip-card-no-buttons-flipped, .flip-card-label-wrap-no-buttons, .flip-card-label-wrap-no-buttons-flipped {
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
}

.flip-card-mobile, .flip-card-mobile-flipped {
  max-width: 180px !important;
  min-width: 180px !important;
  max-height: 230px !important;
  min-height: 230px !important;
}

.result-card-mobile {
  max-width: 180px !important;
  min-width: 180px !important;
  max-height: 202px !important;
  min-height: 202px !important;
}

.result-card {
  height: 305px !important;
}

.result-card-label-wrap {
  height: 325px !important;
}

.flip-card-label-wrap, .flip-card-label-wrap-flipped {
  height: 360px !important;
}

.flip-card-label-wrap-no-buttons, .flip-card-label-wrap-no-buttons-flipped {
  height: 320px !important;
}

.result-card-no-buttons {
  height: 270px !important;
}

.result-card-label-wrap-no-buttons {
  height: 280px !important;
}

.resultcard-description .buttons-mobile {
  margin: auto;
}

.resultcard-description .buttons-mobile .article-button {
  height: 30px;
  width: 30px;
}

.flip-card-mobile-flipped .flip-card-back .resultcard-description {
  position: absolute;
  width: 100%;
  bottom: -211px;
}

.flip-card, .flip-card-flipped {
  height: 340px !important;
}

.flip-card-no-buttons, .flip-card-no-buttons-flipped {
  height: 300px !important;
}

/* Für Edge erforderlich */
.flip-card-label-wrap-flipped .flip-card-front img, .flip-card-flipped .flip-card-front img, .flip-card-mobile-flipped .flip-card-front img, .flip-card-no-buttons-flipped .flip-card-front img, .flip-card-label-wrap-no-buttons-flipped .flip-card-front img {
  visibility: hidden;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-card-label-wrap-flipped .flip-card-inner, .flip-card-flipped .flip-card-inner, .flip-card-mobile-flipped .flip-card-inner, .flip-card-no-buttons-flipped .flip-card-inner, .flip-card-label-wrap-no-buttons-flipped .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-label-wrap h2, .flip-card-label-wrap-flipped h2, .result-card-label-wrap h2, .flip-card-label-wrap-no-buttons h2, .flip-card-label-wrap-no-buttons-flipped h2, .result-card-label-wrap-no-buttons h2 {
  white-space: normal !important;
}

/* This container is needed to position the front and back side */
.flip-card-inner {
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flip-card-front img, .flip-card-front .img {
  max-width: 150px;
  max-height: 180px;
  margin: auto;
}

.flip-card-mobile .flip-card-front div:first-child, .flip-card-mobile-flipped .flip-card-front div:first-child, .result-card-mobile .flip-card-front div:first-child {
  height: 120px;
}

.flip-card-mobile-flipped .flip-card-back .resultcard-title-mobile, .flip-card-mobile .flip-card-back .resultcard-title-mobile {
  height: 140px;
}

.flip-card-front .rotate-btn-mobile {
  margin-top: -75px;
  cursor: pointer;
}

.flip-card-mobile-flipped .flip-card-back .resultcard-description, .flip-card-mobile .flip-card-back .resultcard-description {
  position: absolute;
  width: 100%;
  bottom: -214px;
}

.rotate-btn {
  cursor: pointer;
}

/* Für Edge erforderlich */
/*.flip-card-flipped .flip-card-front img {
  visibility: hidden;
}*/

/* Position the front and back side */
.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

/* Style the front side (fallback if image is missing) */
.flip-card-front {
  background-color: white;
}

/* Style the back side */
.flip-card-back {
  background-color: dodgerblue;
  color: black;
  transform: rotateY(180deg);
}

.flip-card-front div:first-child {
  background-color: white;
}

.flip-card-back .resultcard-title-mobile, .flip-card-back .resultcard-title {
  overflow-y: auto;
}

.flip-card-back-list {
  width: 100%;
  text-align: left;
  font-size: smaller;
}

.resultcard-title {
  text-align: center;
  cursor: pointer;
}

.flip-card-back .resultcard-title {
  height: 220px;
}

.resultcard-title-mobile {
  text-align: center;
  cursor: default;
}

.flip-card-back-list tr td {
  font-size: small;
}

.flip-card-back-list .label-span {
  display: block;
  font-weight: 500;
  font-style: italic;
}

.flip-card-back-list .label-col {
  font-weight: 400;
  font-style: italic;
}

.flip-card-back-list .data-span {
  margin-left: 20px;
}

.flip-card-back-list .data-col {
  padding-left: 5px;
}

.flip-card-no-buttons .flip-card-front .resultcard-description, .flip-card-no-buttons-flipped .flip-card-back .resultcard-description, .flip-card-no-buttons .flip-card-back .resultcard-description, .flip-card-no-buttons-flipped .flip-card-front .resultcard-description {
  width: 100%;
  bottom: -275px;
  position: absolute;
}

.flip-card-label-wrap-no-buttons .flip-card-front .resultcard-description, .flip-card-label-wrap-no-buttons-flipped .flip-card-front .resultcard-description, .flip-card-label-wrap-no-buttons .flip-card-back .resultcard-description, .flip-card-label-wrap-no-buttons-flipped .flip-card-back .resultcard-description {
  width: 100%;
  bottom: -295px;
  position: absolute;
}

.flip-card-back .resultcard-description {
  width: 100%;
  bottom: -315px;
  position: absolute;
}

.flip-card-label-wrap-flipped .flip-card-back .resultcard-description, .flip-card-label-wrap .flip-card-back .resultcard-description {
  bottom: -335px;
}

.flip-card-back .rotate-btn-mobile {
  margin-top: 11px;
  cursor: pointer;
}

.flip-card-mobile-flipped .flip-card-back .rotate-btn-mobile, .flip-card-mobile .flip-card-back .rotate-btn-mobile {
  margin-top: -1px;
}

.resultcard-description .rotate-btn, .resultcard-description .rotate-btn-mobile {
  text-align: center;
}

.resultcard-description .buttons-td, .resultcard-description .buttons-mobile {
  justify-content: center;
  /*margin: auto;
  min-width: fit-content;*/
  display: flex;
  width: auto;
  cursor: pointer;
  height: 48px;
}

.resultcard-description .buttons-td .show.tooltip, .resultcard-description .buttons-mobile .show.tooltip {
  word-wrap: normal;
  max-width: 100px;
}