.subtable-buttons {
  /*min-height: 35px;
    min-width: 35px;
    border-radius: 4px;
    margin-top: 3px;
    margin-bottom: auto;
    margin-left: 5px;
    margin-right: 5px;
    border-width: 1px;*/
  border-radius: 4px;
  border-width: 1px;
  margin-left: 5px;
  margin-right: 5px;
}

.edit-button {
  border-color: #4285f4;
}

/*.subtable-drop-down-toggle {
  font-weight: bold;
  white-space: nowrap;
  border: 0;
  appearance: none;
  cursor: pointer;
}

.subtable-drop-down-toggle::selection {
  border: 0;
}*/

.subtable-head-icon {
  /*float: left;
    margin-top: 13px;
    z-index: 1000;*/
  margin-right: 10px;
  cursor: pointer;
}

.edit-icon {
  color: #4285f4;
}

.subtable-buttons-field {
  min-width: 150px;
}

.edit-selection {
  min-width: 220px;
}
