.drag-drop-border-left {
    margin-top: 5px;
    margin-left: 10px;
    margin-right: 10px;
    border: black;
    border-width: 1px;
    border-style: solid;
}

.drag-drop-border-right {
    margin-top: 5px;
    margin-right: 10px;
    margin-left: 10px;
    border: black;
    border-width: 1px;
    border-style: solid;
}

.drag-drop-lbl {
    font-family: Arial, Helvetica, sans-serif;
    color: black;
    text-align: center;
    margin-bottom: 10px;
    margin-top: -12px;
    background-color: white;
    max-width: max-content;
    margin-left: auto;
    margin-right: auto;
}

.drag-drop-list {
    font-family: Arial, Helvetica, sans-serif;
    font-size: small;
    color: black;
    overflow-y: scroll;
    max-height: 430px;
    margin-bottom: 10px;
}