/*nav {
    background-color: #0b2e3a;
}*/

.checkbox-icon {
  color: #007bff;
  cursor: pointer;
}

.search-menu {
  margin-bottom: 20px;
}

.hide-facet {
  display: none;
}

.mdb-card-facet .section-text {
  font-size: small;
  font-weight: bold;
}

.mdb-card-facet * {
  background-color: white;
}

.icon-top {
  vertical-align: top;
  margin-left: 5px;
}

.ref-file-tab-link {
  color: black;
}

.tab-label {
  display: flex;
}

.custom-table-modal {
  font-weight: normal;
}

.custom-table-modal .modal-category {
  font-weight: 500;
  text-decoration: underline;
}

.custom-table-modal .modal-col {
  margin: auto;
}

.custom-table-modal .btn-row {
  margin-top: 5px;
  margin-left: 0px;
}

.custom-table-modal .btn-row .btn-span {
  white-space: nowrap;
}

.custom-table-modal .btn-row button {
  background-color: white;
  width: 30px;
  height: 30px;
}

.custom-table-modal .btn-row .btn-left {
  position: absolute;
  left: 0px;
}

.custom-table-modal .btn-row .btn-right {
  position: absolute;
  right: 15px;
}

.custom-table-modal .btn-row .create-button,
.create-icon {
  color: green;
  border-color: green;
}

.search-field .search-bar-spinner {
  margin-left: 40%;
  margin-top: 10px;
}

.multidatalist-container .checkbox-container,
.multidatalist-container .checkbox-container label {
  cursor: pointer;
}

.multidatalist-container .checkbox-container {
  white-space: nowrap;
}

.multidatalist-container .checkbox-container label {
  margin-left: 15px !important;
}

.icon-center {
  vertical-align: super;
}

.edit-btn-group {
  height: 43px;
  margin-left: auto;
}

.new-reference-modal-body {
  padding-top: 1rem;
  padding-right: 0rem;
  padding-bottom: 0rem;
  padding-left: 0rem;
}

.new-reference-modal-header {
  margin-bottom: 5px;
}

.new-reference-modal-header *:first-child {
  font-weight: 600 !important;
  margin-left: auto;
}

.edit-btn-group button {
  margin-left: 5px;
  margin-right: 10px;
}

.apply-new-references-button {
  margin-top: 11px;
  margin-bottom: auto;
  margin-left: 10px;
  height: 31px;
}

.view-btn-group {
  margin-top: 5px;
  margin-left: auto;
}

.view-btn-group .btn {
  height: 31px;
  margin-right: 10px;
}

.view-btn-group .custom-switch {
  margin-right: 20px;
  margin-top: 5px;
}

.import-products-modal-table {
  text-align: center;
}

.custom-table {
  margin: auto;
}

.custom-switch, .custom-switch * {
  cursor: pointer;
}

.custom-table .custom-switch {
  margin-top: 4px;
}

.article-reference-table-body tr:nth-last-child(-n + 4) {
  align-items: center;
}

/* Utils.jsx */

.checkbox .form-check {
  padding-left: 0%;
}

.checkbox .form-check .form-check-label {
  cursor: default;
}

/* Home.jsx */

.home-row {
  margin-top: 18px;
}

.home-row-mobile {
  margin-top: -15px;
}

.custom-switch .custom-control-label {
  white-space: nowrap;
}

.excel-table-modal .template-select {
  display: flex;
  margin-right: auto;
}

.excel-table-modal .template-select label {
  margin-top: 6px;
  margin-right: 10px;
}

.excel-table-modal .template-select button {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 10px;
}

.view-btn-group .custom-checkbox {
  margin-right: 20px;
  margin-top: 10px;
}

.navbar_button {
  margin: auto;
}

.shoppingcart-modal .body-container {
  margin-left: 5px;
}

.shoppingcart-modal .list-row {
  margin-top: 10px;
  margin-bottom: 10px;
}

.shoppingcart-modal .list-row div:first-child {
  font-weight: 500;
}

.hidden_facets {
  visibility: hidden;
}

.reset-references-modal .rrm-body div {
  text-align: center;
}

.article-subtable {
  display: grid;
  margin-bottom: 20px;
}

.font-larger {
  font-size: medium !important;
}

.dropdown-toggle {
  font-weight: bold;
}

.dropdown-menu {
    max-height: 250px;
    overflow-y: auto;
    max-width: 350px;
    overflow-x: auto;
}

.dropdown-language .dropdown-item {
  text-align: center;
}

.section-text {
  padding-left: 10px;
  font-size: medium;
  font-weight: bold;
}

.main-tabs {
  background-color: #0da4bc;
  height: 55px;
}

.hits_select_label {
  margin: auto;
  padding-right: 5px;
}

.format-select {
  width: auto;
}

.cursor-wait {
  cursor: wait;
}

.download-selection-block {
  overflow: auto;
  margin-top: 5px;
}

.download-selection-row {
  justify-content: center;
  margin-top: 5px;
  margin-bottom: 5px;
  display: flex;
  flex-wrap: wrap;
}

.hits_select {
  width: auto;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 5px;
}

.main-tabs .nav-link {
  font-size: smaller;
}

.settings-icon {
  margin-right: 5px;
}

/*.Toastify__toast--info {
  opacity: 0.9;
  background-color: rgb(0, 153, 0);
  cursor: default;
  width: auto;
}*/

.toast-text {
  color: white;
  font-size: 0.875rem;
}

/*.Toastify__close-button {
  color: black;
}*/

.toast-cont .Toastify__toast--success, .toast-cont .Toastify__toast--info {
  opacity: 0.9;
  cursor: default;
  width: auto;
}

.toast-cont .Toastify__toast--success {
  background-color: gray;
  /*background-color: rgb(0, 153, 0);*/
}

.toast-btn {
  border:solid 2px #fff;
  background: transparent;
  margin-top: 20px;
  margin-bottom: 10px;
  color: white;
}

.portal-version {
  margin-left: auto;
  margin-right: 10px;
  margin-bottom: 5px;
}

/*
SearchMultiDataList.jsx
*/

.multidatalist-container .form-check {
  padding-left: 0px;
  margin-bottom: 0px !important;
  margin-top: 0px !important;
}

.multidatalist-container .form-check input {
  max-height: 0px;
}

.multidatalist-container {
  margin-top: 5px !important;
  /*max-height: 240px;*/
  overflow-y: auto;
}

.checkbox-doc-count {
  vertical-align: auto;
  margin-left: 10px;
  font-size: small;
  color: rgb(180, 180, 180);
}

.doc-count-selected {
  color: rgb(164, 210, 255);
}

.multidatalist-title {
  font-size: 1rem;
  color: black;
  font-weight: bold;
}

.facet-tab {
  display: none;
}

.facet-tab-show {
  /*margin-top: 30px;*/
  width: 20%;
}

.facet-tab-show-wide {
  width: 30%;
}

.facet-tab-button {
  margin-left: 10px;
  position: absolute;
  border: 0;
  border-radius: 0.25rem;
  height: 55px;
  box-shadow: 0 5px 11px 0 rgb(0 0 0 / 18%), 0 4px 15px 0 rgb(0 0 0 / 15%);
  background-color: white;
  width: 30px;
}

.facet-tab-button-open:hover {
  box-shadow: 0 5px 11px 0 rgb(0 0 0 / 18%), 0 4px 15px 0 rgb(0 0 0 / 15%) !important;
}

.facet-tab-button:hover {
  box-shadow: 0 5px 11px 0 rgb(102 178 255 / 18%),
    0 4px 15px 0 rgb(102 178 255 / 15%);
}

.facet-tab-close-button {
  margin-left: 10px;
  margin-top: 10px;
  box-shadow: 0 5px 11px 0 rgb(0 0 0 / 18%), 0 4px 15px 0 rgb(0 0 0 / 15%);
  cursor: pointer;
  height: 34px;
}

.facet-tab-filter-button {
  position: absolute;
  right: 10px;
  margin-top: 10px;
  box-shadow: 0 5px 11px 0 rgb(0 0 0 / 18%), 0 4px 15px 0 rgb(0 0 0 / 15%);
  cursor: pointer;
}

.facet-tab-filter-icon {
  margin: 5px 10px 5px 10px;
}

.facet-tab-filter-button:hover {
  box-shadow: 0 5px 11px 0 rgb(102 178 255 / 18%),
    0 4px 15px 0 rgb(102 178 255 / 15%);
}

.facet-tab-close-button:hover {
  box-shadow: 0 5px 11px 0 rgb(102 178 255 / 18%),
    0 4px 15px 0 rgb(102 178 255 / 15%);
}

.facet-tab-bar-close-icon {
  margin: 5px 10px 5px 10px;
}

.facet-tab-bar-close-icon i {
  vertical-align: bottom;
}

.facet-tab-bar-icon {
  margin-left: 25%;
  margin-top: 20px;
}

hr.solid {
    border-top: 3px solid #bbb;
  }

.pdf-tab canvas,
.pdf-tab {
  margin: auto;
}

.ref-file-preview .preview-pic {
    cursor: pointer;
}

.picture-modal .modal-body {
  margin: auto;
}

.picture-modal .picture-modal-pic {
  max-width: 700px;
}

.pdf-modal canvas {
  margin: auto;
}

.pdf-modal-head-div {
  display: flex;
}

.html-element-img {
  max-height: 180px;
}

.pdf-modal-head-div .page-controls-head {
  position: absolute;
  left: 50%;
}

.page-controls-head {
  border: 1px solid #f1f1f1;
  background: white;
  transform: translateX(-50%);
  transition: opacity ease-in-out 0.2s;
  border-radius: 4px;
}

.page-controls-head span {
    font: inherit;
    font-size: 0.8em;
    padding: 0 0.5em;
  }

  .page-controls-head button {
    width: 44px;
    height: 44px;
    background: white;
    border: 0;
    font: inherit;
    font-size: 0.8em;
    border-radius: 4px;
  }

  .page-controls-head button:enabled:hover {
    cursor: pointer;
  }

  .page-controls-head button:enabled:hover {
    background-color: #e6e6e6;
  }

  .page-controls-head button:first-child {
    margin-right: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .page-controls-head button:last-child {
      margin-left: 5px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }







.react-pdf__Document:hover .page-controls {
  opacity: 1;
}

.page-controls {
  position: absolute;
  bottom: 5%;
  left: 50%;
  background: white;
  opacity: 0;
  transform: translateX(-50%);
  transition: opacity ease-in-out 0.2s;
  box-shadow: 0 30px 40px 0 rgba(16, 36, 94, 0.2);
  border-radius: 4px;
}

.page-controls span {
    font: inherit;
    font-size: 0.8em;
    padding: 0 0.5em;
  }

  .page-controls button {
    width: 44px;
    height: 44px;
    background: white;
    border: 0;
    font: inherit;
    font-size: 0.8em;
    border-radius: 4px;
  }

  .page-controls button:enabled:hover {
    cursor: pointer;
  }

  .page-controls button:enabled:hover {
    background-color: #e6e6e6;
  }

  .page-controls button:first-child {
    margin-right: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .page-controls button:last-child {
      margin-left: 5px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

.facet-tab-open {
  white-space: nowrap;
  display: flex;
}

.facet-tab-button-show {
  /*text-align: center;*/
  width: 19%;
}

.facet-tab-button-show-wide {
  text-align: center;
  width: 29%;
}

.search-results-normal {
  width: 79%;
}

.search-results-wide {
  width: 69%;
}

.search-results-large {
  margin-left: 30px;
  min-width: stretch;
}

.download-files-modal-table {
  text-align: center;
}

.facet-filter-container {
  margin-top: 65px;
  margin-right: 5%;
  margin-left: 5%;
}

.facet-filter-container-head {
  display: flex;
}

.facet-filter-container-head div {
  min-width: 120px;
  margin-top: auto;
  margin-bottom: auto;
}

.search-field .search-title {
  font-size: 1rem;
  color: black;
  font-weight: bold;
}

.search-field .search-label {
  font-size: medium;
}

.search-field .search-text {
  margin-top: 5px;
  margin-bottom: 5px;
}

/*.multidatalist-container*/
.traffic-light {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 3px;
  /*height: 25px;
    width: 25px;
    border-radius: 50%;
    display: inline-block;*/
}

.checkbox-container i {
  margin-top: 5px;
}

.search-field .datalist-container {
  max-height: 240px;
  overflow-y: auto;
}

/* SearchMultiDataList.jsx */

/*.traffic-light {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    display: inline-block;
}*/

/* SearchMultiList.jsx */

.facet-loading-spinner {
  margin-top: 5px;
  margin-left: 45%;
}

/* DownloadTableModal.jsx */

/*.excel-table-modal {

}*/

.excel-table-modal .collapse-header {
  background-color: transparent;
}

.excel-table-modal .collapse-header .header-text {
  padding-left: 10px;
  font-size: medium;
  font-weight: bold;
}

/* MultiEditField.jsx */

/* CustomTable.jsx */

.loading-products *,
.loading-products span {
  cursor: progress !important;
}

.multi-edit-tr {
  background-color: white !important;
}

.multi-edit-tr-th {
  border-top: 2px solid #dee2e6 !important;
  border-bottom: 2px solid #dee2e6 !important;
  text-align: center;
}

.buttons-td {
  width: 100px;
}

.ss .img-transform {
  transform: scale(0.7);
}

.cursor-default {
  cursor: default !important;
}

/* SearchResults.jsx, CustomTable.jsx */

.table-button-field {
  width: 100px;
  justify-content: center;
  margin: auto;
}

.result-list .buttons-td {
  padding-left: 10px;
}

.table-button-field .buttons-td {
  margin: auto;
  justify-content: center;
}

.grid-view-result-cards .card-wrapper {
  width: auto;
}

.asd {
  height: 100% !important;
}

.ddd {
  height: 500px !important;
}

.list-button-field {
  margin: auto;
  text-align: center;
}

.list-button-field .buttons-td,
.image-buttons-alone .buttons-td {
  margin: auto;
  width: 100px;
  justify-content: center;
}

.table-button-field .article-button,
.image-buttons-alone .article-button,
.list-button-field .article-button {
  margin: 2.5px;
}

.article-button {
  background-color: white;
}

.new-product tr td:first-child {
  width: 35%;
}

/* SearchResults.jsx */

.image-buttons-alone {
  width: 100px;
  margin-top: 25%;
}

/* EditField.jsx */

.edit-selection .form-check {
  max-width: 10px;
}

.edit-checkbox {
  max-width: 10px;
}

/* SimpleModal.jsx */

.simple-modal .body {
  margin-top: 10px;
  white-space: break-spaces;
}

.simple-modal-loading * {
  cursor: progress !important;
}

/* NewTemplateModal.jsx */

/*.save-btn {
    
}*/

/* SearchResults.jsx, Article.jsx, ArticleReferences.jsx */

.article-button {
  height: 35px;
  width: 35px;
  border-radius: 4px;
  margin-top: 3px;
  margin-bottom: auto;
  margin-left: 5px;
  margin-right: 5px;
  border-width: 1px;
}

.info-button {
  border-color: #4285f4;
}

.info-icon {
  color: #4285f4;
}

.excel-button {
  border-color: #1c6c42;
}

.excel-icon {
  color: #1c6c42;
}

.select-button {
  border-color: greenyellow;
}

.select-icon {
  color: greenyellow;
}

.delete-button {
  border-color: #ff3547;
}

.delete-icon {
  color: #ff3547;
}

/* PDF.jsx */

.pdf-button {
  border-color: #ad0b00;
}

.pdf-icon {
  color: #ad0b00;
}

/* SearchDynamicRangeSlider.jsx */

.sparkline {
  padding-top: 15px;
  display: inline-block;
  height: 1em;
  width: 100%;
}

.sparkline .index {
  position: absolute;
  float: left;
  width: 2px;
  height: 1em;
  margin-left: -2px;
}

.sparkline .index .count {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0;
  background: #aaa;
  font: 0/0 a;
  text-shadow: none;
  color: transparent;
}

.xspan {
  width: 4px;
  background-color: red;
  height: 20px;
  left: 12.5%;
  display: block;
  position: relative;
  margin-left: -2px;
}

.xrange {
  padding-bottom: 0px !important;
  padding-top: 0px !important;
  height: 0px !important;
}

.xdiv {
  display: flex;
  width: 100%;
  height: 150px;
}

.file-not-found {
  text-align: center;
}

.file-not-found i {
  color: #e30511;
}

.file-not-found span {
  text-decoration: line-through;
  font-size: small;
  display: block;
}

.selected-filter {
  cursor: pointer;
  margin: 2px 3px;
  padding: 5px 8px;
  font-size: 0.85rem;
  position: relative;
  display: inline-flex;
  background-color: #eee;
  align-items: center;
  padding: 5px 12px;
  color: #424242;
  min-height: 30px;
  border-radius: 3px;
  border: 1px solid transparent;
}

.selected-filter:hover {
  background-color: #cbcbcb;
}

.selected-filter span:first-child {
  max-width: 260px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 20px;
}

.selected-filter:hover span:first-child {
  text-decoration: line-through;
}

.selected-filter span:last-child {
  display: flex;
  height: 100%;
  top: 0px;
  right: 8px;
  position: absolute;
  -webkit-box-align: center;
  align-items: center;
  border-left: 1px solid rgb(89, 89, 89);
  padding-left: 8px;
  margin-left: 8px;
}

.xdiv .index {
  position: relative;
  float: left;
  width: 2px;
  margin-left: -1.9px;
}

.xdiv .index .count {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0;
  background: #aaa;
  font: 0/0 a;
  text-shadow: none;
  color: transparent;
}

.dropdown-searchbar .dropdown-item {
  text-align: left;
}

.dropdown-searchbar {
  margin-left: 5px;
}

.index-creation-date {
  margin-top: 10px;
}

.cursor-default {
  cursor: default !important;
}