.custom-pagination {
   margin: auto;
}

.custom-pagination .pagination-btn {
   background-color: #eee;
   border: transparent;
   border-style: solid;
   border-radius: 3px;
   color: #000000;
   border-width: 1px;
   padding: 5px 12px;
}

.custom-pagination .page-btn {
   margin-left: 2.5px;
   margin-right: 2.5px;
   background-color: #eee;
   border: transparent;
   border-style: solid;
   border-radius: 3px;
   color: #000000;
   border-width: 1px;
   padding: 5px 12px;
}

.custom-pagination .page-btn:hover {
   background-color: #cccccc;
}

.custom-pagination .page-btn-active {
   margin-left: 2.5px;
   margin-right: 2.5px;
   background-color: #0b6aff;
   border: transparent;
   border-style: solid;
   border-radius: 3px;
   color: #ffffff;
   border-width: 1px;
   padding: 5px 12px;
}

.custom-pagination .page-btn-active:hover {
   background-color: #0054d7;
}

.custom-pagination .pagination-btn:hover {
   background-color: #cccccc;
}

.custom-pagination .pagination-placeholder {
   margin-left: 2.5px;
   margin-right: 2.5px;
   background-color: #ffffff;
   border: transparent;
   border-style: solid;
   border-radius: 3px;
   color: #000000;
   border-width: 1px;
   padding: 5px 12px;
   cursor: default;
}