.select-list-lbl {
    font-family: Arial, Helvetica, sans-serif;
    color: black;
    text-align: center;
    margin-bottom: 10px;
    margin-top: -12px;
    background-color: white;
    max-width: max-content;
    margin-left: auto;
    margin-right: auto;
}

.excel-select-list {
    font-family: Arial, Helvetica, sans-serif;
    font-size: small;
    color: black;
    overflow-y: auto;
    max-height: 200px;
    margin-bottom: 10px;
}

.select-item:hover {
    background: lightgrey;
}

.buttons {
    margin: auto;
}

.left-arrow-btn {
    margin-left: auto;
    margin-right: auto;
}

.right-arrow-btn {
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
}

.excel-modal-body {
    margin-top: 0px;
}

.excel-table-container {
    margin-top: 30px;
}

.select-list-border-left {
    margin-top: 5px;
    margin-left: 10px;
    border: black;
    border-width: 1px;
    border-style: solid;
}

.select-list-border-right {
    margin-top: 5px;
    margin-right: 10px;
    border: black;
    border-width: 1px;
    border-style: solid;
}