.sub-table-modal-row {
    padding-top: 10px;
}

.sub-table-modal-row1 {
    margin-top: 30px;
}

.sub-table-modal-col-left {
    margin: auto;
}

.sub-table-modal-col-right {
    margin: auto;
}

.sub-table-modal-body {
    
}

.subtable_modal_table {
    margin-top: 10px;
    overflow-y: scroll;
    max-height: 530px;
}