.search-bar {
  width: 100%;
  justify-content: center;
  color: black;
  margin: auto;
}

.search-bar .data-search-mobile {
  min-width: 350px;
  margin-left: -75px;
}

.search-bar .data-search-advanced {
  width: 70%;
  margin-top: auto;
  margin-bottom: auto;
}

.search-bar .data-search {
  width: 70%;
}

.search-bar .data-search-reference {
  width: inherit;
}