.searchbar_button1 {
    border: 1px solid rgb(204, 204, 204);
    margin-left: 1px;
    text-align: center;
}

.searchbar_button {
    margin-left: 1px;
    text-align: center;
}

.navbar-dropdown {
    margin-left: 0%;
    /*justify-content: flex-end;*/
}

.navbar-dropdown-mobile {
    margin-left: 95% !important;
}