.cs_table {
    text-align: center;
}

.cs_table th {
    font-weight: bold;
    font-size: smaller;
    padding: 20px;
    font-family: Arial, Helvetica, sans-serif;
}

.cs_table td {
    font-size: smaller;
    font-family: Arial, Helvetica, sans-serif;
}

.cs_table tr:nth-child(even) {   
    background-color: #f2f2f2;
}

.body-content {
    padding-top: 5px;
    padding-bottom: 5px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: small;
}

.mdb-card .card-body-content:nth-child(even) {
    background-color: #f2f2f2;
}

.card-body-content .img-transform {
    margin: auto;
}

.card-body-content .content-value {
    max-width: fit-content;
}

.article-tabs-active { 
    border-bottom: 2px solid #0b2e3a;
    font-weight: bold;
    color: black;
    font-size: small;
}

.article-tabs-active:hover {   
    border-bottom: 2px solid #0b2e3a;
    font-weight: bold;
    color: black;
    font-size: small;
}

.body-content-json {
    overflow-x: auto;
    overflow-y: auto;
    max-height: 500px;
}

.article-tabs {   
    color: black;
    font-size: small;
}

.article-tabs:hover {  
    font-weight: bold;
    color: black;
    font-size: small;
}
.mdb-card .section-text {    
    padding-left: 10px;
    font-size: small;
    font-weight: bold;
}

.button {
    background-color: white;
}

.img {
    max-height: 200px;
    max-width: 200px;
}

.switch {
    margin-left: auto;
    margin-right: inherit;
}

.custom-switch {
    margin-top: 10px;
}

.margin-right10 {
    margin-right: 10px;
}

.new_subtable_row_button {
    margin-left: 10px;
}

.ref-pictures-container {
    /*display: grid;
    grid-template-columns: auto auto auto auto;*/
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}