.edit-fields-table {
    width: 100%;
}

.edit-fields-table tr td:first-child {
    
}

.edit-fields-table tr td:nth-last-child(1) {
    text-align: right;
}