.rdrCalendarWrapper {
    display: flex;
}

.rdrMonthAndYearWrapper {
    display: inherit;
}

.rdrMonth {
    width: 100%; 
}