.resultcard-description {
    cursor: default;
}

.result-table {
    margin-top: 20px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: small;
}

.table-img {
    max-width: 100%;
}

.result-table-container, .custom-table-container {
    /*max-height: 520px;*/
    margin-top: 10px;
}

.result-table .result-table-head {
    font-weight: bold;
    text-align: center;
    margin-top: 5px;
    padding-top: 5px;
    margin-bottom: 5px;
    padding-bottom: 5px;
}

.toast-icon {
    margin-left: 10;
}

.result-table td {
    text-align: center;
    font-size: small;
}

.result-table tr:nth-child(even) {   
    background-color: #ebebeb;
}

.result-list {
    width: 100%;
}

.resultlist {
    cursor: default;
    min-height: 290px;
}

.resultlist .custom-select {
    width: auto;
}

.loaded-img-sm img {
    width: 100px !important;
}

.resultlist-img-sm {
    width: 100px !important;
}

.content-fill {
    width: 100% !important;
}

.result-list .result-list-body {
    margin-left: 20px;
    font-size: small;
}

.result-list .result-list-body .col {
    padding: 10px;
}

.result-list .result-list-body:nth-child(even) {   
    background-color: #ebebeb;
}

.result-list .result-list-body .content {
    padding-top: 5px;
    padding-bottom: 5px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: small;
}

.result-list .result-list-body .content:first-child {
    font-weight: bold;
}

.select {
    padding-top: 5px;
    margin-top: -10px;
    margin-bottom: auto;
    min-width: 250px;
}

.results {
    margin-top: 20px;
}

.icon {
    margin-left: 5px;
}

.tst1 {
    margin: auto;
}

.tst2 {
    margin-top: auto;
    margin-bottom: auto;
}

.hits {
    margin-right: 10px;
}

.tst3 {
    margin: auto;
}

.result-stats {
    font-size: medium;
    float: left;
}

.result-stats .label {
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 10px;
}

.result-stats .label-sort {
    max-width: max-content;
    margin-left: auto;
}

.round-button {
    border-radius: 50%;
    height: 40px;
    width: 40px;
    margin-top: 10px;
}

.custom-table-btn {
    margin-top: 25px;
}