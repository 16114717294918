.single-dropdown-list .css-ee3byn-Select {
    display: none;
}

.multi-dropdown-list .css-ee3byn-Select {
    display: none;
}

.multi-dropdown-list .md-form {
    margin-top: 1px;
    margin-bottom: 0px;
    margin-left: 10px;
}

.multi-dropdown-list .md-form .select-dropdown {
    margin-bottom: 0px;
}

.multi-dropdown-list .search-bar-spinner {
    margin-left: 5px;
}

.header-text {
    margin-bottom: 8px;
}