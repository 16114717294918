.home-navbar .logo {
    height: 80px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    align-items: center;
}

.home-navbar .logo img {
    max-height: 80px;
}

/*.home-navbar .navbar-searchbar {
    width: 77%;
}*/

.home-navbar .navbar-dropdown {
    position: absolute;
    right: 0px;
  }