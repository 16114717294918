.drop-down {
    width: auto;
    min-width: 150px;
}

.drop-down-input {
    max-width: 120px;
    text-align: center;
}

.drop-down-button {
    margin-top: 5px;
    padding-top: 5px;
    min-width: 120px;
    background-color: #4285f4;
  border: none;
  color: white;
    border-radius: 2px;
    vertical-align: auto;
    font-size: small;
}